<template>
  <section class="call_to_action">
    <div class="container">
      <div class="row">
        <div class="col-md-9 col-sm-8 col-xs-12">
          <h1>No overtime charges, 7 days a week</h1>
        </div><!--end .col-md-9-->
        <div class="col-md-3 col-sm-4 col-xs-12 text-right cta_responsive_left">
          <a href="" class="btn-gray">GET A FREE QUOTE</a>
        </div><!--end .col-md-3-->
      </div><!--end .row-->
    </div><!--end .container-->
  </section>
</template>

<script>
    export default {
      name: "CallToAction"
    }
</script>

<style scoped>

</style>
