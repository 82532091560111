<template>
  <section class="gallery_area">
    <div class="gallery_width">
      <div class="gallery_fixed_height">
        <img src="/assets/images/gallery/gallery-1.jpg" alt="gallery 1">
        <div class="gallery_overlay">
          <h3>Maintenance and Repair</h3>
          <a href="#" class="gallery_icon_btn text-right"><i class="fa fa-angle-right"></i></a>
        </div><!--end .gallery_overlay-->
      </div><!--end .gallery_fixed_height-->
    </div><!--end .gallery_width-->
    <div class="gallery_width">
      <div class="gallery_fixed_height">
        <img src="/assets/images/gallery/gallery-2.jpg" alt="gallery 2">
        <div class="gallery_overlay">
          <h3>Maintenance and Repair</h3>
          <a href="#" class="gallery_icon_btn text-right"><i class="fa fa-angle-right"></i></a>
        </div><!--end .gallery_overlay-->
      </div><!--end .gallery_fixed_height-->
    </div><!--end .gallery_width-->
    <div class="gallery_width">
      <div class="gallery_fixed_height">
        <img src="/assets/images/gallery/gallery-3.jpg" alt="gallery 3">
        <div class="gallery_overlay">
          <h3>Maintenance and Repair</h3>
          <a href="#" class="gallery_icon_btn text-right"><i class="fa fa-angle-right"></i></a>
        </div><!--end .gallery_overlay-->
      </div><!--end .gallery_fixed_height-->
    </div><!--end .gallery_width-->
    <div class="gallery_width">
      <div class="gallery_fixed_height">
        <img src="/assets/images/gallery/gallery-4.jpg" alt="gallery 4">
        <div class="gallery_overlay">
          <h3>Maintenance and Repair</h3>
          <a href="#" class="gallery_icon_btn text-right"><i class="fa fa-angle-right"></i></a>
        </div><!--end .gallery_overlay-->
      </div><!--end .gallery_fixed_height-->
    </div><!--end .gallery_width-->
    <div class="gallery_width">
      <div class="gallery_fixed_height">
        <img src="/assets/images/gallery/gallery-5.jpg" alt="gallery 5">
        <div class="gallery_overlay">
          <h3>Maintenance and Repair</h3>
          <a href="#" class="gallery_icon_btn text-right"><i class="fa fa-angle-right"></i></a>
        </div><!--end .gallery_overlay-->
      </div><!--end .gallery_fixed_height-->
    </div><!--end .gallery_width-->
    <div class="gallery_width">
      <div class="gallery_fixed_height">
        <img src="/assets/images/gallery/gallery-6.jpg" alt="gallery 6">
        <div class="gallery_overlay">
          <h3>Maintenance and Repair</h3>
          <a href="#" class="gallery_icon_btn text-right"><i class="fa fa-angle-right"></i></a>
        </div><!--end .gallery_overlay-->
      </div><!--end .gallery_fixed_height-->
    </div><!--end .gallery_width-->
  </section>
</template>

<script>
    export default {
      name: "Gallery"
    }
</script>

<style scoped>

</style>
