<template>
  <section class="service_area section_padding">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="hero-section-title text-center">
            <h1>Our services</h1>
          </div><!--end .hero-section-title-->
        </div><!--end .col-md-12-->
       
        <div class="col-md-4 col-sm-6">
          <div class="service_box">
            <div class="service_img">
              <img src="/assets/images/services/service-2.jpg" alt="service 2">
              <div class="icon-box">
                <i class="icon-droplet"></i>
              </div><!-- /.icon-box -->
            </div><!--end .service_img-->
            <div class="service_details">
              <nuxt-link to="/contact"><h2>Water</h2></nuxt-link>
              <p>
                We install bathroom and kitchen plumbing fixtues and heating systems.  We service hot water heaters too.
              </p>
              <nuxt-link to="/contact" class="btn-yellow">LEARN MORE</nuxt-link>
            </div><!--end .service_details-->
          </div><!--end .service_box-->
        </div><!--end .col-md-4-->
		 <div class="col-md-4 col-sm-6">
          <div class="service_box">
            <div class="service_img">
              <img src="/assets/images/services/service-1.jpg" alt="service 1">
              <div class="icon-box">
                <i class="icon-fire"></i>
              </div><!-- /.icon-box -->
            </div><!--end .service_img-->
            <div class="service_details">
              <nuxt-link to="/contact"><h2>Gas</h2></nuxt-link>
              <p>
               New construction gas line installation as well as gas line repairs.  Call us today.
              </p>
              <nuxt-link to="/contact" class="btn-yellow">LEARN MORE</nuxt-link>
            </div><!--end .service_details-->
          </div><!--end .service_box-->
        </div><!--end .col-md-4-->
        <div class="col-md-4 col-sm-6">
          <div class="service_box">
            <div class="service_img">
              <img src="/assets/images/services/service-3.jpg" alt="service 3">
              <div class="icon-box">
                <i class="icon-toilet"></i>
              </div><!-- /.icon-box -->
            </div><!--end .service_img-->
            <div class="service_details">
              <nuxt-link to="/contact"><h2>Sewer</h2></nuxt-link>
              <p>
                We are expert installers but can also clean clogged drains, make small and major reports, and service fixtures.
              </p>
              <nuxt-link to="/contact" class="btn-yellow">LEARN MORE</nuxt-link>
            </div><!--end .service_details-->
          </div><!--end .service_box-->
        </div><!--end .col-md-4-->
      </div><!--end .row-->
    </div><!--end .container-->
  </section>
</template>

<script>
    export default {
      name: "Services"
    }
</script>

<style scoped>

</style>
