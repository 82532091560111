<template>
  <section class="about_area section_padding">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="about_image about-image__updated"></div>
        </div><!--end .col-md-6-->
        <div class="col-md-6">
          <div class="about_details">
            <div class="hero-title-with-shape">
              <h4 class="heading_with_border">Welcome to Vest Plumbing and Gas</h4>
              <h1>Plumbing repair and installation company</h1>
            </div><!--end .hero-title-with-shape-->
            <p>We have over 40 years of experience proudly servicing the Decatur area!  Our customers' trust is a source of great pride and inspires us to keep improving our business.  We're prepared to help you with any questions you may have or any situations you may encounter.</p>
            <a href="#" class="btn-yellow">LEARN MORE</a>
          </div><!--end .about_details-->
        </div><!--end .col-md-6-->
      </div><!--end .row-->
    </div><!--end .container-->
  </section>
</template>

<script>
    export default {
      name: "About"
    }
</script>

<style scoped>

</style>
