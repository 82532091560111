<template>
  <section class="cta_style_2_area">
    <div class="cta_style_2_img_left cta_style_2_img_right"></div>
    <div class="cta_style_2_left">
      <p>Air Conditioning Services</p>
      <h1>Cool it down</h1>
    </div><!--end .cta_style_2_left-->
    <div class="cta_style_2_left cta_style_2_right text-right">
      <p>Heating Services</p>
      <h1>Heat it up</h1>
    </div><!--end .cta_style_2_left .cta_style_2_right-->
  </section>
</template>

<script>
    export default {
      name: "CallToActionTwo"
    }
</script>

<style scoped>

</style>
