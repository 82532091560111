<template>
  <div class="feature_service_area text-center">
    <div class="feature_service_box_width">
      <i class="icon-wallet"></i>
      <p>No Upfront Payments</p>
    </div><!--end .feature_service_box_width-->
    <div class="feature_service_box_width">
      <i class="icon-diploma"></i>
      <p>Satisfaction Guarantee</p>
    </div><!--end .feature_service_box_width-->
    <div class="feature_service_box_width">
      <i class="icon-big_clock"></i>
      <p>Emergency Service</p>
    </div><!--end .feature_service_box_width-->
    <div class="feature_service_box_width">
      <i class="icon-handshake"></i>
      <p>Fixed Right Promise</p>
    </div><!--end .feature_service_box_width-->
    <div class="feature_service_box_width">
      <i class="icon-mechanic"></i>
      <p>8 Years Experience</p>
    </div><!--end .feature_service_box_width-->
  </div>
</template>

<script>
    export default {
      name: "Features"
    }
</script>

<style scoped>

</style>
