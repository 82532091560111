import { render, staticRenderFns } from "./Gallery.vue?vue&type=template&id=795f948c&scoped=true&"
import script from "./Gallery.vue?vue&type=script&lang=js&"
export * from "./Gallery.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "795f948c",
  null
  
)

export default component.exports