<template>
  <section class="header_slider_area owl-carousel">
    <div class="header_slider_bg">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <p><span class="subtitle_number">01</span> Reliable Plumbing</p>
            <h1>Installation Services &amp; Repair</h1>
            <div class="slide_button">
              <a href="/contact" class="btn-yellow">GET A FREE QUOTE</a>
            </div><!--end .slide_button-->
          </div><!--end .col-md-6-->
        </div><!--end .row-->
      </div><!--end .container-->
    </div><!--end .header_slider_bg-->
    <div class="header_slider_bg slider_bg2">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <p><span class="subtitle_number">02</span> Choose the Best Company</p>
            <h1>Plumbing Specialists</h1>
            <div class="slide_button">
              <a href="/contact" class="btn-yellow">GET A FREE QUOTE</a>
            </div><!--end .slide_button-->
          </div><!--end .col-md-6-->
        </div><!--end .row-->
      </div><!--end .container-->
    </div><!--end .header_slider_bg-->
    <div class="header_slider_bg slider_bg3">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <p><span class="subtitle_number">03</span> Quality that is Guaranteed</p>
            <h1>Expert Repair Plumbing Services</h1>
            <div class="slide_button">
              <a href="/contact" class="btn-yellow">GET A FREE QUOTE</a>
            </div><!--end .slide_button-->
          </div><!--end .col-md-6-->
        </div><!--end .row-->
      </div><!--end .container-->
    </div><!--end .header_slider_bg-->
  </section>
</template>

<script>
    export default {
      name: "Slider",
      mounted() {
        $('.header_slider_area').owlCarousel({
          items: 1,
          autoHeight: true,
          autoplay: true,
          loop: true,
          nav: false,
          dots: true,
          slideSpeed: 300,
          animateIn: 'slideInLeft',
          animateOut: 'fadeOutRight',
          autoplayTimeout: 7000,
        });
      }
    }
</script>

<style scoped>

</style>
